import { MODULE_LIST } from "../../globles";
import {
  CLEAR_APP_NOTIFICATION,
  CLEAR_APP_TOUR, CLEAR_FEEDBACK_DETAILS, GENERATE_OTP,
  GENERATE_OTP_SUCCESS, GET_ALL_SIMILAR_APPS,
  GET_ALL_SIMILAR_APPS_SUCCESS, GET_APP_CONFIG,
  GET_APP_CONFIG_SUCCESS,
  GET_APP_DYNAMIC_ALGO,
  GET_APP_DYNAMIC_ALGO_SUCCESS,
  GET_APP_ERROR,
  GET_APP_HEALTH_STATUS,
  GET_APP_HEALTH_STATUS_ERROR,
  GET_APP_HEALTH_STATUS_SUCCESS,
  GET_APP_LANGUAGES_SUCCESS,
  GET_APP_LEADERBOARD_INFO_SUCCESS,
  GET_APP_MASTER_CMS_SUCCESS, GET_APP_NOTIFICATION, GET_APP_NOTIFICATION_SUCCESS, GET_APP_TOUR,
  GET_APP_TOUR_SUCCESS, GET_APP_TRANSLATIONS_SUCCESS, GET_FEEDBACK_DETAILS,
  GET_FEEDBACK_DETAILS_SUCCESS, GET_FLASH_NEWS,
  GET_FLASH_NEWS_SUCCESS, GET_RECENTLY_ADDED,
  GET_RECENTLY_ADDED_SUCCESS, GET_TOP_MODULE,
  GET_TOP_MODULE_SUCCESS, SET_APP_LANG,
  SET_APP_PLATFORM, SET_APP_SCROLLING, SET_APP_UNDER_MAINTENANCE, SET_NOTIFICATION_POPUP, STORE_APP_TIME, STORE_FEEDBACK_DETAILS,
  STORE_FEEDBACK_DETAILS_SUCCESS, STORE_OLD_APP_TOURID, VERIFIED_OTP,
  VERIFIED_OTP_SUCCESS
} from "../types";

const INIT_STATE = {
  loader: false,
  topModulesLoader: false,
  flashNewsLoader: false,
  allSimilarAppsLoader: false,
  recentlyAddedLoader: false,
  appStatusloader: false,
  healthFacility: {},
  appTranslations: {
    F_CASE_FINDING: 'Case Findings',
    F_MANG_PATIENT: 'Manage TB Patient',
    F_REF_HEALTH_FACILITY: 'Referal Health Facility',
    F_RESO_MATERIAL: 'Resource Material',
    F_FEATURES: 'Features',
    N_NEWS_FEED: 'News Feed',
    SIMILAR_APPLI: 'Similar Applications',
    RECENTLY_ADDED: 'Recently Added',
    WE_R_KEEP_IMPROVING_YOUR_IMP_F_US: 'We are keep improving.. your feedback is important for us',
    MODULE: 'Module',
    MORE_TOOLS: 'More Tools',
    U_HAVE_SUBMIT_ALL_FEEDBACK: 'You have submitted all feedback.',
    OTHER_SUGGESTIONS: 'Other Suggestions',
    SUBMIT: 'Submit',
    FEEDBACK: 'Feedback',
    COMPLETION_CERTIFICATE: 'Completion Certificate',
    AVAIL_FACILITIES: 'Available Facilities',
    LEVEL: 'Level',
    BRONZE: 'Bronze',
    GOLD: 'Gold',
    SILVER: 'Silver',
    OTHER_MODULES: 'Other Modules',
    TASKS: 'Tasks',
    MINUTES_SPENT: 'Minutes Spent',
    SUB_MODULE_VISITED: 'Sub Module Visited',
    CHATBOT_USAGE: 'Chatbot Usage',
    RESOURCE_MATERIAL_USAGE: 'Resource Material Usage',
    APP_OPENED: 'App. Opened',
    POINTS: 'Points',
    EDIT_PERSONAL_DETAILES: 'Edit Personal Details',
    EDIT_PROFILE_PICTURE: 'Edit Profile Picture',
    CHANGE_APPLICATION_LANG: 'Change Application Language',
    SHARE_APP_LINK: 'Share Application Link',
    SIGN_OUT: 'Sign Out',
    UPDATE_DETAILS: 'Update Details',
    HEALTH_FACILITY: 'Health Facility',
    DISTRICT: 'District',
    CADER: 'Cadre',
    CADER_TYPE: 'Cadre Type',
    HEALTH_FACILITY_LVL: 'Health-facility Level',
    PLACEHOLDER_MOBILE_NUMBER: 'Mobile Number',
    LINK_CHANGE_MOBILE_NUMBER: 'Change Mobile Number',
    VERIFICATION_OTP_MESSAGE_ONE: 'We have sent an OTP to your mobile number',
    VALIDATION_MOBILE_NUMBER: 'Mobile Number must be at least 10 digit',
    HEADER_MOBILE_NO: 'Mobile No',
    PERFORMANCE: 'Performance',
    CURRENT_ASSMNT: 'Current Assessment',
    PAST_ASSMNT: 'Past Assessment',
    FUTURE_ASSMNT: 'Future Assessment',
    BTN_CANCEL: 'Cancel',
    CONFIRM_SUBMIT: 'Confirm Submit',
    ATTEMPTED: 'Attempted',
    SKIPPED: 'Skipped',
    OUT_OF_TOTAL: 'Out Of Total',
    VIEW: 'View',
    VIEW_CERTIFICATE: 'View Certificate',
    ASSESSMENT_NAME: 'Assessment Name',
    NO_OF_QUES: 'No. of Quetion',
    TIME_TO_COMPLETE: 'Time to Complete',
    COMPLETED_ON: 'Completed on',
    COMPLETION_RATE: 'Completion Rate',
    ASSESSMENT: 'Assessment',
    ACCURACY: 'Accuracy',
    CERTIFICATE: 'Certificates',
    DOWNLOAD: 'Download',
    LINK_FORGOT_PASSWORD: 'Forgot Password',
    HEADER_PASSWORD: 'Forgot Password',
    BTN_GET_OTP: 'Get OTP',
    ADD_PROF_PIC: 'Add Profile Picture',
    HEADER_CREATE_ACCOUNT: 'Create Account',
    LINK_RESEND_OTP: 'Resend OTP',
    BTN_VERIFY: 'Verify',
    LINK_CHANGE_MOBILE_NUMBER: 'Change Mobile Number',
    ACCOUNT_CHANGE_PASSWORD: 'Change Password',
    HEADER_CHANGE_PASS: 'Change Password',
    HEADER_HEALTH_FACILITY: 'Health Facility',
    SEARCH_HEALTH_FACILITY: 'Search Health Facility',
    HEADER_STATE: 'State',
    HEADER_FACILITIES: 'Facilities',
    PLACEHOLDER_SEARCH: 'Search',
    VIEW_ALL: 'View All',
    DETAILED_SCORE: 'Detailed Score',
    TAB_HOME: 'Home',
    BRONZE_MEDAL: 'Bronze Medal',
    SILVER_MEDAL: 'Silver Medal',
    GOLD_MEDAL: 'Gold Medal',
    ASSESSMENT_COMPLETION_CERTI: 'Assessment Certificates',
    LEADERBOARD_INFOTAB_HEADER: 'Ni-kshay Setu Leaderbord will nurture highly motiveated users through a culture of recognation and gamified competitions. It will reinforce best in app. performance by recognizing achievements in engaging ways. Understand your progress in application and by achiving certain tasks you will get a reward as a medal to boost up your efforts. Here is the information about all app. performance levels and process to achive that level.',
    TAB_NAME_LEADERBOARD: 'Leaderboard',
    TAB_NAME_TASKS: 'Tasks',
    TAB_NAME_ACHIEVEMENTS: 'Achievements',
    TAB_NAME_INFORMATION: 'Information',
    HEADER_SCREENING_TOOL: 'Screening Tool',
    BTN_START: 'Start',
    NUTRITION_OUTCOME: 'Nutrition Outcome',
    THANK_U_FOR_THE_INPUTS: 'Thank you for the inputs',
    SELECT_AGE_WEIGHT_AND_HEIGHT: 'Select age,weight and height',
    SCREENING_HEADER_HEIGHT_CM: 'Height (cm)',
    SCREENING_HEADER_WEIGHT_KG: 'Weight (Kg)',
    SCREENING_HEADER_AGE_Y: 'Age (Year)',
    NEXT_BTN: 'Next',
    BTN_SUBMIT: 'Submit',
    QUES_ARE_U_EXPERIENCING_ANY_SYMPTOMS: 'Are you experiencing any of the following symptoms',
    SCREENING_STEPER_LABEL_SYMPTOMS: 'Symptoms',
    HEADER_SURVEY_FORM: 'Survey Form',
    SURVEY_FORM_DESCRIPTION_TOP: 'Survey form will help us to understand users requirements and based on that we will create meaningfull content for the users.',
    SURVEY_FORM_DESCRIPTION_BOTTOM: 'Please be a part of this survey and help us to build a better content.',
    HEADER_APP_LANG: 'Application Language',
    FOLLOW_US_ON_SOCIAL_MEDIA: 'Follow us on social media',
    FB_SOCIAL_MEDIA_NAME: 'Ni-kshay.Setu',
    INSTA_SOCIAL_MEDIA_NAME: 'Ni-kshay.Setu',
    WELCOME: 'Welcome',
    MASTER_SEARCH_TAB_FAQ: 'FAQ',
    MASTER_SEARCH_RESOURCE_MATERIAL: 'Resource Material',
    MASTER_SEARCH_TAB_SUB_MODULES: '	Sub Modules',
    MASTER_SEARCH_TAB_MODULES: 'Modules',
  },
  appMasterCms: [],
  dynamicAlogs: MODULE_LIST,
  appLang: 'en',
  appUnderMaintenance: false,
  platform: null,
  appStatus: {},
  flashNews: [],
  appLanggusges: [{ title: "English", code: "en" }, { title: 'हिंदी', code: 'hi' }, { title: 'ગુજરાતી', code: 'gu' }, { title: 'मराठी', code: 'mr' }],
  allSimilarApps: [],
  feedBackDetails: [],
  isFeedbackModal: false,
  topModules: [],
  recentlyAdded: [],
  appTime: null,
  isScrolling: true,
  notificationObj: {},
  tourLoader: false,
  dynamicAlogsloader: false,
  tourSlider: [],
  oldTourIds: [],
  notificationList: [],
  notificationObj: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APP_CONFIG: {
      return {
        ...state,
        loader: true,
        appUnderMaintenance: false,
      };
    }
    case GET_APP_CONFIG_SUCCESS: {
      return {
        ...state,
        loader: false,
        healthFacility: action.payload,
      };
    }
    case GET_APP_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        loader: false,
        appTranslations: action.payload,
      };
    }
    case GET_APP_LEADERBOARD_INFO_SUCCESS: {
      return {
        ...state,
        loader: false,
        leaderboardInfo: action.payload,
      };
    }
    case GET_APP_LANGUAGES_SUCCESS: {
      return {
        ...state,
        loader: false,
        appLanggusges: action.payload,
      };
    }
    case GET_APP_MASTER_CMS_SUCCESS: {
      return {
        ...state,
        loader: false,
        appMasterCms: action.payload,
      };
    }
    case GENERATE_OTP: {
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_OTP_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case VERIFIED_OTP: {
      return {
        ...state,
        loader: true,
      };
    }
    case VERIFIED_OTP_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    case SET_APP_LANG: {
      return {
        ...state,
        appLang: action.payload,
      };
    }
    case SET_APP_PLATFORM: {
      return {
        ...state,
        platform: action.payload,
      };
    }
    case SET_APP_UNDER_MAINTENANCE: {
      return {
        ...state,
        appUnderMaintenance: true,
      };
    }
    case GET_APP_HEALTH_STATUS: {
      return {
        ...state,
        appStatusloader: true,
        appStatus: {},
      };
    }
    case GET_APP_HEALTH_STATUS_SUCCESS: {
      return {
        ...state,
        appStatusloader: false,
        appStatus: action.payload
      };
    }
    case GET_APP_HEALTH_STATUS_ERROR: {
      return {
        ...state,
        // loappStatusloaderader: false,
        appStatusloader: false, //BUG FIX INFINITE LOADER iOS
        appStatus: action.payload
      };
    }
    case GET_APP_DYNAMIC_ALGO: {
      return {
        ...state,
        dynamicAlogsloader: true,
      };
    }
    case GET_APP_DYNAMIC_ALGO_SUCCESS: {
      const oldArray = Object.assign([], MODULE_LIST)
      return {
        ...state,
        dynamicAlogsloader: false,
        dynamicAlogs: oldArray.map((item) => {
          const DynamicArray = action.payload?.[item.sectionKey]
          if (DynamicArray) {
            const UpdateData = Object.assign([], item?.data)

            for (let index = 0; index < DynamicArray.length; index++) {
              const element = DynamicArray[index];
              if (item.sectionKey === 'RESOURCE_MATERIALS') {
                UpdateData.push({
                  icon: element?.icon_type,
                  cardTitle: element?.title,
                  link: "ResourceMaterials",
                  type: element?.icon_type,
                  activityType: "module_Resource_Materials_" + element?.icon_type,
                  id: element?.id,
                })
              } else
                UpdateData.push({
                  icon: 'algorithm.svg',
                  cardTitle: element?.title || element?.name,
                  type: item.sectionKey == 'NTEP' ? "CGC" : 'Dynamic',
                  link: 'AlgorithmList',
                  id: element?.id,
                  imageUrl: element?.media?.[0]?.id ?
                    element?.media?.[0]?.id + '/' + element?.media?.[0]?.file_name : null,
                  activityType: 'module_' + (element?.title || element?.name),
                })
            }

            return {
              id: item?.id,
              sectionKey: item?.sectionKey,
              sectionTitle: item?.sectionTitle,
              sectionIcon: item?.sectionIcon,
              data: UpdateData
            }
          }
          return item
        }),
      };
    }
    case GET_APP_ERROR: {
      return {
        ...state,
        loader: false,
        dynamicAlogsloader: false,
        appStatusloader: false,
        isFeedbackModal: false,
        topModulesLoader: false,
        flashNewsLoader: false,
        allSimilarAppsLoader: false,
        recentlyAddedLoader: false,
        tourLoader: false,
      };
    }
    case GET_FLASH_NEWS: {
      return {
        ...state,
        flashNewsLoader: true,
      };
    }
    case GET_FLASH_NEWS_SUCCESS: {
      return {
        ...state,
        flashNewsLoader: false,
        flashNews: action.payload,
      };
    }
    case GET_ALL_SIMILAR_APPS: {
      return {
        ...state,
        allSimilarAppsLoader: true,
      };
    }
    case GET_ALL_SIMILAR_APPS_SUCCESS: {
      return {
        ...state,
        allSimilarAppsLoader: false,
        allSimilarApps: action.payload,
      };
    }
    case GET_FEEDBACK_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_FEEDBACK_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        feedBackDetails: action.payload?.data,
        isFeedbackModal: action.payload?.modal,
      };
    }
    case CLEAR_FEEDBACK_DETAILS: {
      return {
        ...state,
        loader: false,
        feedBackDetails: [],
        isFeedbackModal: false
      };
    }
    case STORE_FEEDBACK_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case STORE_FEEDBACK_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        isFeedbackModal: false,
        feedBackDetails: [],
      };
    }
    case GET_TOP_MODULE: {
      return {
        ...state,
        topModulesLoader: true,
      };
    }
    case GET_TOP_MODULE_SUCCESS: {
      return {
        ...state,
        topModulesLoader: false,
        topModules: action.payload,
      };
    }
    case GET_RECENTLY_ADDED: {
      return {
        ...state,
        recentlyAddedLoader: true,
      };
    }
    case GET_RECENTLY_ADDED_SUCCESS: {
      return {
        ...state,
        recentlyAddedLoader: false,
        recentlyAdded: action.payload,
      };
    }
    case STORE_APP_TIME: {
      console.log("STORE_APP_TIME", action.payload);
      return {
        ...state,
        appTime: action.payload,
      };
    }
    case SET_APP_SCROLLING: {
      return {
        ...state,
        isScrolling: action.payload,
      };
    }
    case SET_NOTIFICATION_POPUP: {
      return {
        ...state,
        notificationObj: action.payload,
      };
    }
    case STORE_OLD_APP_TOURID: {
      return {
        ...state,
        oldTourIds: action.payload,
      };
    }
    case GET_APP_TOUR: {
      return {
        ...state,
        tourLoader: true,
        tourSlider: []
      };
    }
    case GET_APP_TOUR_SUCCESS: {
      const newTourAray = action.payload;
      const updateSlides = Object.assign([]);
      for (let index = 0; index < newTourAray.length; index++) {
        if (state.oldTourIds.find(e => e == newTourAray[index]?.id)) {
        } else {
          updateSlides.push(newTourAray[index]);
        }

      }
      return {
        ...state,
        tourLoader: false,
        tourSlider: updateSlides,
      };
    }
    case CLEAR_APP_TOUR: {
      return {
        ...state,
        tourLoader: false,
        tourSlider: [],
      };
    }
    case GET_APP_NOTIFICATION: {
      return {
        ...state,
        loader: true
      };
    }
    case GET_APP_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        loader: false,
        notificationList: [...state.notificationList, ...action.payload?.data],
        notificationObj: action.payload,
      };
    }
    case CLEAR_APP_NOTIFICATION: {
      return {
        ...state,
        notificationList: [...[]],
        notificationObj: {},
      };
    }
    default:
      return state;
  }
};
