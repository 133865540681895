/** @jsxImportSource theme-ui */
import queryString from "query-string";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Text } from "theme-ui";
import CurrentAssessmentTab from "../../components/Assessment/CurrentAssessmentTab";
import FutureAssessmentTab from "../../components/Assessment/FutureAssessmentTab";
import PastAssessmentTab from "../../components/Assessment/PastAssessmentTab";
import PerformanceTab from "../../components/Assessment/PerformanceTab/PerformanceTab";
import ProfileContainer from "../../components/ProfileContainer";
import TitleTag from "../../components/TitleTag";
const Assessment = () => {
  const history = useHistory();
  const queryObj = queryString?.parse(history.location.search);
  const { appTranslations } = useSelector((state) => state?.app);
  
  return (
    <>
      <TitleTag title="Assessment" />
      <section
        sx={{ variant: "layout.Home", pb: 0 }}
        className="leaderboard-page"
      >
        <div className="row align-items-start g-0">
          <div className="col-lg-3">
            <div
              className="leaderboard nav flex-column nav-pills me-lg-3 pt-2 min-vh-100"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <div className="text-center" sx={{ mb: 60 }}>
                <ProfileContainer />
              </div>
              <button
                onClick={() => {
                  history.push("/Assessment?tab=performance");
                }}
                className={`nav-link ${
                  queryObj?.tab === "performance" ? "active" : ""
                }`}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon me-2"
                >
                  <path d="M13 9H18.5L13 3.5V9ZM6 2H14L20 8V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V4C4 2.89 4.89 2 6 2ZM7 20H9V14H7V20ZM11 20H13V12H11V20ZM15 20H17V16H15V20Z" />
                </svg>
                <Text variant="Raleway18">{appTranslations.PERFORMANCE}</Text>
              </button>
              <button
                onClick={() => {
                  history.push("/Assessment?tab=CurrentAssessments");
                }}
                className={`nav-link ${
                  queryObj?.tab === "CurrentAssessments" ? "active" : ""
                }`}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon me-2"
                >
                  <path d="M3.50078 23.6667C3.00967 23.6667 2.60356 23.5061 2.28245 23.185C1.96134 22.8639 1.80078 22.4578 1.80078 21.9667V4.96667C1.80078 4.47556 1.96134 4.06944 2.28245 3.74833C2.60356 3.42722 3.00967 3.26667 3.50078 3.26667H9.22411C9.35634 2.60556 9.68217 2.0625 10.2016 1.6375C10.7211 1.2125 11.3208 1 12.0008 1C12.6808 1 13.2805 1.2125 13.7999 1.6375C14.3194 2.0625 14.6452 2.60556 14.7774 3.26667H20.5008C20.9919 3.26667 21.398 3.42722 21.7191 3.74833C22.0402 4.06944 22.2008 4.47556 22.2008 4.96667V21.9667C22.2008 22.4578 22.0402 22.8639 21.7191 23.185C21.398 23.5061 20.9919 23.6667 20.5008 23.6667H3.50078ZM3.50078 21.9667H20.5008V4.96667H18.8008V7.51667H5.20078V4.96667H3.50078V21.9667ZM12.0008 4.96667C12.3219 4.96667 12.5911 4.85806 12.8083 4.64083C13.0255 4.42361 13.1341 4.15444 13.1341 3.83333C13.1341 3.51222 13.0255 3.24306 12.8083 3.02583C12.5911 2.80861 12.3219 2.7 12.0008 2.7C11.6797 2.7 11.4105 2.80861 11.1933 3.02583C10.9761 3.24306 10.8674 3.51222 10.8674 3.83333C10.8674 4.15444 10.9761 4.42361 11.1933 4.64083C11.4105 4.85806 11.6797 4.96667 12.0008 4.96667Z" />
                  <path d="M6.42078 16.6532L7.0947 16.2041L6.43455 15.2147L7.0947 14.2253L6.42078 13.7761L5.94774 14.4851L5.4747 13.7761L4.80078 14.2253L5.46093 15.2147L4.80078 16.2041L5.4747 16.6532L5.94774 15.9443L6.42078 16.6532Z" />
                  <path d="M7.8075 10.1719L7.23406 9.6001L5.8986 10.9318L5.37422 10.4089L4.80078 10.9807L5.8986 12.0755L7.8075 10.1719Z" />
                  <path d="M7.8075 18.5718L7.23406 18L5.8986 19.3317L5.37422 18.8088L4.80078 19.3806L5.8986 20.4754L7.8075 18.5718Z" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.17041 14.4719L17.7104 14.4719C18.1356 14.4719 18.4804 14.6228 18.4804 15.0905C18.4804 15.5583 18.1356 15.7679 17.7104 15.7679H9.17041C8.74516 15.7679 8.40039 15.5583 8.40039 15.0905C8.40039 14.6228 8.74516 14.4719 9.17041 14.4719Z"
                  />
                  <path d="M18.4804 10.824C18.4804 10.4661 18.0996 10.176 17.6298 10.176H9.25099C8.78123 10.176 8.40039 10.4661 8.40039 10.824C8.40039 11.182 8.78123 11.472 9.25099 11.472H17.6298C18.0996 11.472 18.4804 11.182 18.4804 10.824Z" />
                  <path d="M18.4804 19.4161C18.4804 19.0581 18.0996 18.7681 17.6298 18.7681H9.25099C8.78123 18.7681 8.40039 19.0581 8.40039 19.4161C8.40039 19.774 8.78123 20.0641 9.25099 20.0641H17.6298C18.0996 20.0641 18.4804 19.774 18.4804 19.4161Z" />
                </svg>
                <Text variant="Raleway18">
                  {appTranslations.CURRENT_ASSMNT}
                </Text>
              </button>
              <button
                onClick={() => {
                  history.push("/Assessment?tab=PastAssessments");
                }}
                className={`nav-link ${
                  queryObj?.tab === "PastAssessments" ? "active" : ""
                }`}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon me-2"
                >
                  <path d="M5.21961 16.6532L5.89353 16.2041L5.23338 15.2147L5.89353 14.2253L5.21961 13.7761L4.74657 14.4851L4.27353 13.7761L3.59961 14.2253L4.25976 15.2147L3.59961 16.2041L4.27353 16.6532L4.74657 15.9443L5.21961 16.6532Z" />
                  <path d="M6.60633 10.1719L6.03289 9.6001L4.69742 10.9318L4.17305 10.4089L3.59961 10.9807L4.69742 12.0755L6.60633 10.1719Z" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.88674 14.3999L15.5117 14.3999C15.8914 14.3999 16.1992 14.5396 16.1992 14.9727C16.1992 15.4058 15.8914 15.5999 15.5117 15.5999H7.88674C7.50704 15.5999 7.19922 15.4058 7.19922 14.9727C7.19922 14.5396 7.50704 14.3999 7.88674 14.3999Z"
                  />
                  <path d="M17.9992 10.824C17.9992 10.4661 17.5912 10.176 17.0879 10.176H8.11057C7.60726 10.176 7.19922 10.4661 7.19922 10.824C7.19922 11.182 7.60726 11.472 8.11057 11.472H17.0879C17.5912 11.472 17.9992 11.182 17.9992 10.824Z" />
                  <path d="M17.4276 24C15.9497 24 14.685 23.4689 13.6334 22.4067C12.5819 21.3445 12.0561 20.067 12.0561 18.5742C12.0561 17.0813 12.5819 15.8038 13.6334 14.7416C14.685 13.6794 15.9497 13.1483 17.4276 13.1483C18.9055 13.1483 20.1703 13.6794 21.2218 14.7416C22.2734 15.8038 22.7992 17.0813 22.7992 18.5742C22.7992 20.067 22.2734 21.3445 21.2218 22.4067C20.1703 23.4689 18.9055 24 17.4276 24ZM19.0761 21.4737L19.8718 20.6699L17.7403 18.5167V15.3014H16.6319V18.9187L19.0761 21.4737ZM2.90448 22.4498C2.4308 22.4498 2.02817 22.2823 1.69659 21.9474C1.36501 21.6124 1.19922 21.2057 1.19922 20.7273V4.01914C1.19922 3.54067 1.34606 3.13397 1.63975 2.79904C1.93343 2.46411 2.18448 2.29665 2.3929 2.29665H8.13396C8.26659 1.62679 8.59343 1.07656 9.11448 0.645933C9.63553 0.215311 10.2371 0 10.9192 0C11.6013 0 12.2029 0.215311 12.724 0.645933C13.245 1.07656 13.5718 1.62679 13.7045 2.29665H19.4455C19.9192 2.29665 20.3218 2.46411 20.6534 2.79904C20.985 3.13397 21.1508 3.54067 21.1508 4.01914V12.488C20.8666 12.3158 20.5871 12.1675 20.3124 12.0431C20.0376 11.9187 19.7487 11.8086 19.4455 11.7129V4.01914H16.4329V7.7512H5.91711V4.01914H2.90448V20.7273H10.6634C10.7582 21.0144 10.8719 21.2967 11.0045 21.5742C11.1371 21.8517 11.2982 22.1435 11.4876 22.4498H2.90448ZM11.4308 3.90431C11.7529 3.90431 12.0229 3.79426 12.2408 3.57416C12.4587 3.35407 12.5676 3.08134 12.5676 2.75598C12.5676 2.43062 12.4587 2.15789 12.2408 1.9378C12.0229 1.7177 11.7529 1.60766 11.4308 1.60766C11.1087 1.60766 10.8387 1.7177 10.6208 1.9378C10.4029 2.15789 10.294 2.43062 10.294 2.75598C10.294 3.08134 10.4029 3.35407 10.6208 3.57416C10.8387 3.79426 11.1087 3.90431 11.4308 3.90431Z" />
                </svg>
                <Text variant="Raleway18">
                  {appTranslations.CARD_ASSESSEMENT_PAST_ASSESSMENTS}
                </Text>
              </button>
              <button
                onClick={() => {
                  history.push("/Assessment?tab=FutureAssessment");
                }}
                className={`nav-link ${
                  queryObj?.tab === "FutureAssessment" ? "active" : ""
                }`}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon me-2"
                >
                  <path d="M3.50078 23.6667C3.00967 23.6667 2.60356 23.5061 2.28245 23.185C1.96134 22.8639 1.80078 22.4578 1.80078 21.9667V4.96667C1.80078 4.47556 1.96134 4.06944 2.28245 3.74833C2.60356 3.42722 3.00967 3.26667 3.50078 3.26667H9.22411C9.35634 2.60556 9.68217 2.0625 10.2016 1.6375C10.7211 1.2125 11.3208 1 12.0008 1C12.6808 1 13.2805 1.2125 13.7999 1.6375C14.3194 2.0625 14.6452 2.60556 14.7774 3.26667H20.5008C20.9919 3.26667 21.398 3.42722 21.7191 3.74833C22.0402 4.06944 22.2008 4.47556 22.2008 4.96667V21.9667C22.2008 22.4578 22.0402 22.8639 21.7191 23.185C21.398 23.5061 20.9919 23.6667 20.5008 23.6667H3.50078ZM3.50078 21.9667H20.5008V4.96667H18.8008V7.51667H5.20078V4.96667H3.50078V21.9667ZM12.0008 4.96667C12.3219 4.96667 12.5911 4.85806 12.8083 4.64083C13.0255 4.42361 13.1341 4.15444 13.1341 3.83333C13.1341 3.51222 13.0255 3.24306 12.8083 3.02583C12.5911 2.80861 12.3219 2.7 12.0008 2.7C11.6797 2.7 11.4105 2.80861 11.1933 3.02583C10.9761 3.24306 10.8674 3.51222 10.8674 3.83333C10.8674 4.15444 10.9761 4.42361 11.1933 4.64083C11.4105 4.85806 11.6797 4.96667 12.0008 4.96667Z" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.91669 14.4719L17.0833 14.4719C17.5896 14.4719 18 14.6228 18 15.0905C18 15.5583 17.5896 15.7679 17.0833 15.7679H6.91669C6.41043 15.7679 6 15.5583 6 15.0905C6 14.6228 6.41043 14.4719 6.91669 14.4719Z"
                  />
                  <path d="M18 10.824C18 10.4661 17.5466 10.176 16.9874 10.176H7.01261C6.45338 10.176 6 10.4661 6 10.824C6 11.182 6.45338 11.472 7.01261 11.472H16.9874C17.5466 11.472 18 11.182 18 10.824Z" />
                  <path d="M18 19.4161C18 19.0581 17.5466 18.7681 16.9874 18.7681H7.01261C6.45338 18.7681 6 19.0581 6 19.4161C6 19.774 6.45338 20.0641 7.01261 20.0641H16.9874C17.5466 20.0641 18 19.774 18 19.4161Z" />
                </svg>
                <Text variant="Raleway18">{appTranslations.FUTURE_ASSMNT}</Text>{" "}
              </button>
            </div>
          </div>
          <div className="col-lg-9 vh-100 overflow-auto">
            {queryObj?.tab === "PastAssessments" ? (
              <PastAssessmentTab />
            ) : queryObj?.tab === "CurrentAssessments" ? (
              <CurrentAssessmentTab />
            ) : queryObj?.tab === "FutureAssessment" ? (
              <FutureAssessmentTab />
            ) : (
              <PerformanceTab />
            )}
          </div>
        </div>
      </section>
    </>
  );
};
export default Assessment;
