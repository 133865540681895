//get signup data
export const GET_ALL_STATE = "GET_ALL_STATE";
export const GET_ALL_STATE_SUCCESS = "GET_ALL_STATE_SUCCESS";
export const GET_DISTRICT_BY_STATE = "GET_DISTRICT_BY_STATE";
export const GET_DISTRICT_BY_STATE_SUCCESS = "GET_DISTRICT_BY_STATE_SUCCESS";
export const GET_BLOCK_BY_DISTRICT = "GET_BLOCK_BY_DISTRICT";
export const GET_BLOCK_BY_DISTRICT_SUCCESS = "GET_BLOCK_BY_DISTRICT_SUCCESS";
export const GET_ALL_CADRE = "GET_ALL_CADRE";
export const GET_ALL_CADRE_SUCCESS = "GET_ALL_CADRE_SUCCESS";
export const GET_HEALTH_BY_BLOCK = "GET_HEALTH_BY_BLOCK";
export const GET_HEALTH_BY_BLOCK_SUCCESS = "GET_HEALTH_BY_BLOCK_SUCCESS";
export const GET_ALL_CADRE_TYPE = "GET_ALL_CADRE_TYPE";
export const GET_ALL_CADRE_TYPE_SUCCESS = "GET_ALL_CADRE_TYPE_SUCCESS";
export const CLEAR_DISTRICT = "CLEAR_DISTRICT";
export const CLEAR_BLOCK = "CLEAR_BLOCK";
export const CLEAR_HELTH = "CLEAR_HELTH";
export const CLEAR_CRADE = "CLEAR_CRADE";

//Store signup data
export const REQUEST_REGISTER = "REQUEST_REGISTER";
export const REQUEST_REGISTER_SUCCESS = "REQUEST_REGISTER_SUCCESS";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
//Store login data
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const REQUEST_LOGIN_SUCCESS = "REQUEST_LOGIN_SUCCESS";

// assessment
export const ASSESSMENT_ERROR = "ASSESSMENT_ERROR";
export const GET_ALL_ASSESSMENT = "GET_ALL_ASSESSMENT";
export const GET_ALL_ASSESSMENT_SUCCESS = "GET_ALL_ASSESSMENT_SUCCESS";
export const GET_ASSESSMENT_WITH_QUESTION = "GET_ASSESSMENT_WITH_QUESTION";
export const GET_ASSESSMENT_WITH_QUESTION_SUCCESS =
  "GET_ASSESSMENT_WITH_QUESTION_SUCCESS";
export const GET_USER_ASSESSMENT_DETAILS = "GET_USER_ASSESSMENT_DETAILS";
export const GET_USER_ASSESSMENT_DETAILS_SUCCESS =
  "GET_USER_ASSESSMENT_DETAILS_SUCCESS";
export const STORE_ASSESSMENT_RESULT = "STORE_ASSESSMENT_RESULT";
export const STORE_ASSESSMENT_RESULT_SUCCESS =
  "STORE_ASSESSMENT_RESULT_SUCCESS";
export const GET_USER_ASSESSMENT_RESULT = "GET_USER_ASSESSMENT_RESULT";
export const GET_USER_ASSESSMENT_RESULT_SUCCESS =
  "GET_USER_ASSESSMENT_RESULT_SUCCESS";
export const GET_USER_PAST_ASSESSMENT = "GET_USER_PAST_ASSESSMENT";
export const GET_USER_PAST_ASSESSMENT_SUCCESS =
  "GET_USER_PAST_ASSESSMENT_SUCCESS";
export const CLEAR_CURRENT_ASSESSMENT = "CLEAR_CURRENT_ASSESSMENT";
export const CLEAR_PAST_ASSESSMENT = "CLEAR_PAST_ASSESSMENT";
export const GET_USER_FUTURE_ASSESSMENT = "GET_USER_FUTURE_ASSESSMENT";
export const GET_USER_FUTURE_ASSESSMENT_SUCCESS =
  "GET_USER_FUTURE_ASSESSMENT_SUCCESS";
export const CLEAR_FUTURE_ASSESSMENT = "CLEAR_FUTURE_ASSESSMENT";
// Materials
export const GET_MATERIALS = "GET_MATERIALS";
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS";
export const GET_ROOT_FOLDERS = "GET_ROOT_FOLDERS";
export const GET_ROOT_FOLDERS_SUCCESS = "GET_ROOT_FOLDERS_SUCCESS";
export const CLEAR_MATERIALS = "CLEAR_MATERIALS";
export const STORE_ASSESSMENT_ENROLLNMENT = "STORE_ASSESSMENT_ENROLLNMENT";
export const STORE_ASSESSMENT_ENROLLNMENT_SUCCESS =
  "STORE_ASSESSMENT_ENROLLNMENT_SUCCESS";

// Algorithms
export const GET_ALGORITHMS_MASTER_NODES = "GET_ALGORITHMS_MASTER_NODES";
export const GET_ALGORITHMS_MASTER_NODES_SUCCESS =
  "GET_ALGORITHMS_MASTER_NODES_SUCCESS";
export const GET_ALGORITHMS_DEPENDENT_NODES = "GET_ALGORITHMS_DEPENDENT_NODES";
export const GET_ALGORITHMS_DEPENDENT_NODES_SUCCESS =
  "GET_ALGORITHMS_DEPENDENT_NODES_SUCCESS";
export const ALGORITHMS_FLOW = "ALGORITHMS_FLOW";
export const CLEAN_ALGORITHMS_FLOW = "CLEAN_ALGORITHMS_FLOW";
export const CLEAN_ALGORITHMS_DEPENDENT_NODES =
  "CLEAN_ALGORITHMS_DEPENDENT_NODES";
export const CLEAN_ALGORITHMS_MASTER_NODES = "CLEAN_ALGORITHMS_MASTER_NODES";
export const PUSH_TO_CHAT_FLOW = "PUSH_TO_CHAT_FLOW";
export const CLEAN_CHAT_FLOW = "CLEAN_CHAT_FLOW";
export const LOAD_MORE_DATA = "LOAD_MORE_DATA";
export const LOAD_MORE_DATA_SUCCESS = "LOAD_MORE_DATA_SUCCESS";
export const GET_LATENT_TB_NODES = "GET_LATENT_TB_NODES";
export const GET_LATENT_TB_NODES_SUCCESS = "GET_LATENT_TB_NODES_SUCCESS";
export const GET_DYNAMIC_ALGORITHMS_MASTER_NODES = "GET_DYNAMIC_ALGORITHMS_MASTER_NODES";
export const GET_DYNAMIC_ALGORITHMS_MASTER_NODES_SUCCESS = "GET_DYNAMIC_ALGORITHMS_MASTER_NODES_SUCCESS";
export const GET_DYNAMIC_ALGORITHMS_DEPENDENT_NODES = "GET_DYNAMIC_ALGORITHMS_DEPENDENT_NODES";
export const GET_DYNAMIC_ALGORITHMS_DEPENDENT_NODES_SUCCESS = "GET_DYNAMIC_ALGORITHMS_DEPENDENT_NODES_SUCCESS";
// chat
export const GET_KEYWORDS = "GET_KEYWORDS";
export const GET_KEYWORDS_SUCCESS = "GET_KEYWORDS_SUCCESS";
export const GET_QUESTIONS_BY_KEYWORD = "GET_QUESTIONS_BY_KEYWORD";
export const GET_QUESTIONS_BY_KEYWORD_SUCCESS =
  "GET_QUESTIONS_BY_KEYWORD_SUCCESS";
export const SUBMIT_QUESTIONS_HIT = "SUBMIT_QUESTIONS_HIT";
export const SUBMIT_QUESTIONS_HIT_SUCCESS = "SUBMIT_QUESTIONS_HIT_SUCCESS";
export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS";
export const SEARCH_BY_KEYWORDS = "SEARCH_BY_KEYWORDS";
export const SEARCH_BY_KEYWORDS_STATIC_SUCCESS = "SEARCH_BY_KEYWORDS_STATIC_SUCCESS"
export const SEARCH_BY_KEYWORDS_SUCCESS = "SEARCH_BY_KEYWORDS_SUCCESS";
export const PUSH_LOADER = "PUSH_LOADER";
export const POP_LOADER = "POP_LOADER";
// Chapters
export const GET_ALL_CHAPTERS = "GET_ALL_CHAPTERS";
export const GET_ALL_CHAPTERS_SUCCESS = "GET_ALL_CHAPTERS_SUCCESS";
export const GET_ALL_CHAPTERS_BY_ID = "GET_ALL_CHAPTERS_BY_ID";
export const GET_ALL_CHAPTERS_BY_ID_SUCCESS = "GET_ALL_CHAPTERS_BY_ID_SUCCESS";
export const CLEAR_ALL_CHAPTERS = "CLEAR_ALL_CHAPTERS";

// Screening
export const GET_ALL_SYMPTOMS = "GET_ALL_SYMPTOMS";
export const GET_ALL_SYMPTOMS_SUCCESS = "GET_ALL_SYMPTOMS_SUCCESS";
export const STORE_USER_SCREENING = "STORE_USER_SCREENING";
export const STORE_USER_SCREENING_SUCCESS = "STORE_USER_SCREENING_SUCCESS";
// Filter
export const GET_FILTER_DETAILS = "GET_FILTER_DETAILS";
export const GET_FILTER_DETAILS_SUCCESS = "GET_FILTER_DETAILS_SUCCESS";
export const CLEAR_FILTER_DETAILS = "CLEAR_FILTER_DETAILS";
export const SET_STATE_ID = "SET_STATE_ID";
export const SET_DISTRICT_ID = "SET_DISTRICT_ID";
export const SET_BLOCK_ID = "SET_BLOCK_ID";
export const SET_FACILITIES = "SET_FACILITIES";
export const SET_FILTER_PAGE = "SET_FILTER_PAGE";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_SORT_BY = "SET_SORT_BY";
export const CLEAR_FILTER = "CLEAR_FILTER";
// User
export const GET_USERDATA = "GET_USERDATA";
export const GET_USERDATA_SUCCESS = "GET_USERDATA_SUCCESS";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
// app
export const GET_APP_CONFIG = "GET_APP_CONFIG";
export const GET_APP_CONFIG_SUCCESS = "GET_APP_CONFIG_SUCCESS";
export const GET_APP_TRANSLATIONS_SUCCESS = "GET_APP_TRANSLATIONS_SUCCESS";
export const GET_APP_LEADERBOARD_INFO_SUCCESS = "GET_APP_LEADERBOARD_INFO_SUCCESS";
export const GET_APP_LANGUAGES_SUCCESS = "GET_APP_LANGUAGES_SUCCESS";
export const GET_APP_MASTER_CMS_SUCCESS = "GET_APP_MASTER_CMS_SUCCESS";
export const STORE_USER_ACTIVITY = "STORE_USER_ACTIVITY";
export const GENERATE_OTP = "GENERATE_OTP";
export const GENERATE_OTP_SUCCESS = "GENERATE_OTP_SUCCESS";
export const CLEAR_GENERATE_OTP = "CLEAR_GENERATE_OTP";
export const VERIFIED_OTP = "VERIFIED_OTP";
export const VERIFIED_OTP_SUCCESS = "VERIFIED_OTP_SUCCESS";
export const SET_APP_LANG = "SET_APP_LANG";
export const GET_APP_DYNAMIC_ALGO = "GET_APP_DYNAMIC_ALGO";
export const GET_APP_DYNAMIC_ALGO_SUCCESS = "GET_APP_DYNAMIC_ALGO_SUCCESS";
export const SET_APP_UNDER_MAINTENANCE = "SET_APP_UNDER_MAINTENANCE";
export const STORE_USER_DEVICE_TOKENS = "STORE_USER_DEVICE_TOKENS";
export const GET_APP_HEALTH_STATUS = "GET_APP_HEALTH_STATUS";
export const GET_APP_HEALTH_STATUS_SUCCESS = "GET_APP_HEALTH_STATUS_SUCCESS";
export const GET_APP_HEALTH_STATUS_ERROR = "GET_APP_HEALTH_STATUS_ERROR";
export const GET_APP_ERROR = "GET_APP_ERROR";
export const REMOVE_NOTIFICATION_TOKEN = "REMOVE_NOTIFICATION_TOKEN";
export const SET_APP_PLATFORM = "SET_APP_PLATFORM";
export const GET_FLASH_NEWS = "GET_FLASH_NEWS";
export const GET_FLASH_NEWS_SUCCESS = "GET_FLASH_NEWS_SUCCESS";
export const GET_ALL_SIMILAR_APPS = "GET_ALL_SIMILAR_APPS";
export const GET_ALL_SIMILAR_APPS_SUCCESS = "GET_ALL_SIMILAR_APPS_SUCCESS";
export const GET_FEEDBACK_DETAILS = "GET_FEEDBACK_DETAILS";
export const GET_FEEDBACK_DETAILS_SUCCESS = "GET_FEEDBACK_DETAILS_SUCCESS";
export const CLEAR_FEEDBACK_DETAILS = "CLEAR_FEEDBACK_DETAILS";
export const STORE_FEEDBACK_DETAILS = "STORE_FEEDBACK_DETAILS";
export const STORE_FEEDBACK_DETAILS_SUCCESS = "STORE_FEEDBACK_DETAILS_SUCCESS";
export const STORE_MODULE_USAGE = "STORE_MODULE_USAGE";
export const STORE_MODULE_USAGE_SUCCESS = "STORE_MODULE_USAGE_SUCCESS";
export const GET_TOP_MODULE = "GET_TOP_MODULE";
export const GET_TOP_MODULE_SUCCESS = "GET_TOP_MODULE_SUCCESS";
export const GET_RECENTLY_ADDED = "GET_RECENTLY_ADDED";
export const GET_RECENTLY_ADDED_SUCCESS = "GET_RECENTLY_ADDED_SUCCESS";
export const STORE_OLD_APP_TOURID = "STORE_OLD_APP_TOURID";
export const CLEAR_APP_TOUR = "CLEAR_APP_TOUR";
export const GET_APP_TOUR = "GET_APP_TOUR";
export const GET_APP_TOUR_SUCCESS = "GET_APP_TOUR_SUCCESS";
export const STORE_APP_TIME = "STORE_APP_TIME";
export const SET_APP_SCROLLING = "SET_APP_SCROLLING";
export const SET_NOTIFICATION_POPUP = "SET_NOTIFICATION_POPUP";
export const CLEAR_APP_NOTIFICATION = "CLEAR_APP_NOTIFICATION";
export const GET_APP_NOTIFICATION = "GET_APP_NOTIFICATION";
export const GET_APP_NOTIFICATION_SUCCESS = "GET_APP_NOTIFICATION_SUCCESS";
// Contact Us
export const CONTACT_US = "CONTACT_US";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";

// Change Password
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const VERIFIED_FORGOT_PASSWORD_OTP = "VERIFIED_FORGOT_PASSWORD_OTP";
export const VERIFIED_FORGOT_PASSWORD_OTP_SUCCESS = "VERIFIED_FORGOT_PASSWORD_OTP_SUCCESS";


//UserDetails

export const USER_DATA = "LEADERBOARD_DATA";
export const GET_USER_DETAILES_SUCCESS = "GET_LEADERBOARD_DETAILES_SUCCESS";


//LeaderBoard
export const CLEAR_LEADERBOARD_DATA = "CLEAR_LEADERBOARD_DATA";
export const LEADERBOARD_DATA = "LEADERBOARD_DATA";
export const GET_LEADERBOARD_DETAILES_SUCCESS = "GET_LEADERBOARD_DETAILES_SUCCESS";

//LeaderBoardTask

export const LEADERBOARD_TASK_LIST = "LEADERBOARD_TASK_LIST";
export const LEADERBOARD_TASK_LIST_SUCCESS = "LEADERBOARD_TASK_LIST_SUCCESS";
export const GET_ACHIVEMENT = "GET_ACHIVEMENT";
export const GET_ACHIVEMENT_SUCCESS = "GET_ACHIVEMENT_SUCCESS";
export const GET_ALL_CERTIFICATES = "GET_ALL_CERTIFICATES";
export const GET_ALL_CERTIFICATES_SUCCESS = "GET_ALL_CERTIFICATES_SUCCESS";

//SURVEY

export const GET_ALL_SURVEY_SUCCESS = "GET_ALL_SURVEY_SUCCESS";
export const GET_ALL_SURVEY = "GET_ALL_SURVEY";
export const GET_ALL_QUESTION = "GET_ALL_QUESTION";
export const GET_ALL_QUESTION_SUCCESS = "GET_ALL_QUESTION_SUCCESS";
export const CLEAR_ALL_QUESTION = "CLEAR_ALL_QUESTION";
export const CLEAR_SURVEY = "GET_ALL_QUESTION_SUCCESS";
export const STORE_SURVEY_QUESTION = "STORE_SURVEY_QUESTION";
export const STORE_SURVEY_QUESTION_SUCCESS = "STORE_SURVEY_QUESTION_SUCCESS";



//performance

export const GET_ASSESSMENT_PERFORMANCE_SUCCESS = "GET_ASSESSMENT_PERFORMANCE_SUCCESS";
export const GET_ASSESSMENT_PERFORMANCE = "GET_ASSESSMENT_PERFORMANCE";

//master Search
export const GET_MASTER_SEARCH = "GET_MASTER_SEARCH";
export const GET_MASTER_SEARCH_SUCCESS = "GET_MASTER_SEARCH_SUCCESS";
export const CLEAR_MASTER_SEARCH = 'CLEAR_MASTER_SEARCH';
export const MASTER_SEARCH_ERROR = 'MASTER_SEARCH_ERROR';
export const GET_MODULE_MASTER_SEARCH = "GET_MODULE_MASTER_SEARCH";
export const GET_MODULE_MASTER_SEARCH_SUCCESS = "GET_MODULE_MASTER_SEARCH_SUCCESS";
export const GET_SUB_MODULE_MASTER_SEARCH = "GET_SUB_MODULE_MASTER_SEARCH";
export const GET_SUB_MODULE_MASTER_SEARCH_SUCCESS = "GET_SUB_MODULE_MASTER_SEARCH_SUCCESS";
export const GET_RM_MASTER_SEARCH = "GET_RM_MASTER_SEARCH";
export const GET_RM_MASTER_SEARCH_SUCCESS = "GET_RM_MASTER_SEARCH_SUCCESS";
export const GET_FAQ_MASTER_SEARCH = "GET_FAQ_MASTER_SEARCH";
export const GET_FAQ_MASTER_SEARCH_SUCCESS = "GET_FAQ_MASTER_SEARCH_SUCCESS";